<template>
  <div v-if="data" class="overflow-x-hidden">
    <div class="w-full top-0 fixed z-50">
      <div class="inner-bg absolute top-0 w-full"></div>
      <div class="top-bg absolute grid grid-cols-3 bg-theme-1 px-2 w-full">
        <div class="col-start-1 col-span-1">
          <v-icon
              @click="goBack"
              right
              dark
              class="h-full"
          >
            mdi-arrow-left
          </v-icon>
        </div>
        <div class="col-start-2">
          <h1 class="font-montserrat-regular text-white text-center h-full flex items-center justify-center whitespace-nowrap">resultados de orden</h1>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-32">
        <div class="flex justify-center">
          <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_101_54)">
              <path d="M55 110C85.3757 110 110 85.3757 110 55C110 24.6243 85.3757 0 55 0C24.6243 0 0 24.6243 0 55C0 85.3757 24.6243 110 55 110Z" fill="#FF7A00"/>
              <path d="M54.5 27L78.3157 68.25H30.6843L54.5 27Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_101_54">
                <rect width="110" height="110" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="mt-3 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          description :
        </span>
          <br>
          <p class="orange-text font-montserrat-bold text-theme-6 pr-5">
          {{ data.Resultado }}
        </p>
        </div>
        <div class="mt-3 mx-5">
          <p class="font-montserrat-regular">Encuadre correctamente la imagen con margen suficiente.
            Evita reflejos en la pantalla de la fusionadora.
            Evite mover el telefono al hacer la foto. </p>
        </div>
      </div>
      <div class="mt-2 px-5 pb-5 w-full">
        <v-btn @click="goToUpload" block color="#4A4D4E" outlined class="text-theme-3 font-montserrat-light capitalize justify-between mt-3" x-large>
          Repitir Orden
          <v-icon
              right
              dark
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
// import redirect from "@/mixins/redirect";

import {mapGetters} from "vuex";

export default {
  name: "NotRecognizingImg",
  // mixins: [redirect],
  data() {
    return {
      data: null
    }
  },
  computed: {
    ...mapGetters({
      notRecognize: "uploadImage/verificationGetter"
    })
  },
  mounted() {
    this.data = JSON.parse(this.notRecognize)
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    },
    goToUpload() {
      return this.$router.push("/add-new")
    }
  }
}
</script>
<style lang="scss" scoped>
.top-bg {
  height: 90px;
  z-index: 2;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
.inner-bg {
  height: 95px;
  background: #F48B8B;
  z-index: 1;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
.v-btn {
  border-radius: 12px !important;
  ::v-deep &__content {
    justify-content: space-between;
  }
}
.orange-text {
  color: #FF7A00;
}
</style>

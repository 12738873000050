<template>
  <div :class="{ loader: true, fadeout: !isLoading }" class="bg-theme-1 h-screen w-screen flex justify-center align-center relative z-50 top-0 left-0">
    <img class="loading-img" src="../assets/img/img_4.png" alt="">
    <div class="absolute right-3 bottom-2">
      <h1 class="font-montserrat-bold text-white text-right text-2xl">Seitech</h1>
      <span class="font-montserrat-regular text-white text-right text-base">Sistema Piloto de Fusionadoras</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading",
  props: ["isLoading"]
}
</script>
<style lang="scss" scoped>
.loading-img {
  height: 125px;
  width: 222px;
}
.loader {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

import UploadImgService from "@/service/uploadService/UploadImgService";

export default {
    uploadImageAction(state ,payload) {
        return UploadImgService.uploadImg(payload)
    },
    updateVerificationAction({commit}, payload) {
        commit("VERIFICATION_MUTATION", payload)
    }
}

import state from "./moduleLoginState"
import getters from "./moduleLoginGetters"
import mutations from "./moduleLoginMutaions"
import actions from "./moduleLoginActions"
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

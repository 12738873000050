import LoginService from "@/service/loginService/LoginService";

export default {
    contactSalesAction(state ,payload) {
        return LoginService.loginUser(payload)
    },
    async userDataAction({commit}, payload) {
        await LoginService.userData(payload).then((response) => {
            commit("USER_DATA_MUTATION", response.data)
        })
    },
    async userJobDetailsAction({commit}, payload) {
        await LoginService.userJobDetails(payload).then((response) => {
            commit("USER_JOB_DETAILS", response.data)
        })
    },
    async userJobsByDate({commit}, payload) {
        await LoginService.userJobDateFilter(payload.userId, payload.payload).then((response) => {
            commit("USER_DATA_MUTATION", response.data)
        })
    }
}

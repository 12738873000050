import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import vuetify from './plugins/vuetify'
import "./vee-validate"
import VueUploadComponent from "vue-upload-component"
import router from "@/router";
import  WebCam  from "vue-web-cam";
import store from "./store/store"
Vue.component('file-upload', VueUploadComponent)
import redirect from "./mixins/redirect"
import VueSessionStorage from "vue-sessionstorage";
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

Vue.use(VueSessionStorage);
Vue.use(WebCam)
Vue.config.productionTip = false
Vue.use(redirect)
new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

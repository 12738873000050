import state from "./moduleUploadImgState"
import getters from "./moduleUploadImgGetters"
import mutations from "./moduleUploadImgMutaions"
import actions from "./moduleUploadImgActions"
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

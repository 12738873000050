export default  {
    mounted() {
        this.$session.set('username', this.$store.state.loginUser.username);
        if(this.$session.get("username")) {
            return true
        } else {
            this.$router.push("/").then(() => {})
        }
    }
}

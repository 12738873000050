import Vue from 'vue'
import Vuex from 'vuex'
import moduleLogin from "@/store/Login/moduleLogin";
import moduleUploadImg from "@/store/UploadImg/moduleUploadImg";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        loginUser: moduleLogin,
        uploadImage: moduleUploadImg
    }
})

import axios from "axios"

export default class BaseService {
    static instance;
    axiosInstance;
    baseURL = "https://fusionadorasvodafone-admin.seitech.es"

    constructor(axios) {
        this.axiosInstance = axios
    }

    static getInstance() {
        if (this.instance === undefined) {
            this.instance = new BaseService(
                axios.create({
                    baseURL: "https://fusionadorasvodafone-admin.seitech.es",
                    headers: {
                        'Content-Type': `multipart/form-data;`
                    },
                })
            )
        }
        return this.instance
    }
    getData(path, payload) {
        return this.axiosInstance.get(path, payload)
            .then(response => response)
            .catch(error => {
                return Promise.reject(error.response)
            })
    }

    postData(path, payload) {
        return this.axiosInstance.post(path, payload)
            .then(response => response)
            .catch(error => {
                return Promise.reject(error.response)
            })
    }

    putData(path, payload, params) {
        return this.axiosInstance.put(path, payload, params)
            .then(response => response)
            .catch(error => {
                return Promise.reject(error.response)
            })
    }

    patchData(path, payload) {
        return this.axiosInstance.patch(path, payload)
            .then(response => response)
            .catch(error => {
                return Promise.reject(error.response)
            })
    }

    deleteData(path) {
        return this.axiosInstance.delete(path)
            .then(response => response)
            .catch(error => {
                return Promise.reject(error.response)
            })
    }
}

import BaseService from "@/service/BaseService";
export default {
    loginUser(payload) {
        return BaseService.getInstance().postData("/exist_user",payload)
    },
    userData(payload) {
        return BaseService.getInstance().getData(`users/${payload}/jobs`)
    },
    userJobDetails(payload) {
        return BaseService.getInstance().getData(`jobs/${payload}`)
    },
    userJobDateFilter(userId, date) {
        return BaseService.getInstance().getData(`users/${userId}/jobs/${date}`)
    }
}

<template>
  <v-app>
    <router-view/>
<!--    <SignIn />-->
<!--    <Orders />-->
<!--    <UploadImage />-->
<!--    <OrderDetails />-->
<!--    <Verification />-->
  </v-app>
</template>

<script>
//
//import SignIn from "@/components/SignIn";
// import Orders from "@/components/Orders";
// import UploadImage from "@/components/UploadImage";
// import OrderDetails from "@/components/OrderDetails";
// import Verification from "@/components/Verification";
export default {
  name: 'App',
  components: {
    // Verification,
    // OrderDetails,
    // UploadImage
    // Orders
    // SignIn
  },
};
</script>
<style lang="scss">
</style>

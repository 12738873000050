<template>
  <div v-if="data" class="overflow-hidden h-screen">
    <div class="w-full top-0 fixed z-50">
      <div class="inner-bg absolute top-0 w-full"></div>
      <div class="top-bg absolute grid grid-cols-3 bg-theme-1 px-2 w-full">
        <div class="col-start-1 col-span-1">
          <v-icon
              @click="goBack"
              right
              dark
              class="h-full"
          >
            mdi-arrow-left
          </v-icon>
        </div>
        <div class="col-start-2">
          <h1 class="font-montserrat-regular text-white text-center h-full flex items-center justify-center whitespace-nowrap">resultados de orden</h1>
        </div>
      </div>
    </div>
    <div class="grid grid-rows-2">
      <div class="mt-32 row-start-1">
        <div class="flex justify-center">
          <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_23_1294)">
              <path d="M55 110C85.3757 110 110 85.3757 110 55C110 24.6243 85.3757 0 55 0C24.6243 0 0 24.6243 0 55C0 85.3757 24.6243 110 55 110Z" fill="#00C968"/>
              <path d="M77.5876 38.7519C76.6789 37.8519 75.2057 37.8519 74.2969 38.7519L46.4189 66.3603L35.7031 55.7482C34.7944 54.8482 33.3212 54.8483 32.4123 55.7482C31.5035 56.648 31.5035 58.107 32.4123 59.007L44.7735 71.2484C45.6819 72.1483 47.1562 72.1476 48.0643 71.2484L77.5876 42.0108C78.4964 41.1109 78.4963 39.6519 77.5876 38.7519Z" fill="white" stroke="white" stroke-width="4.88889"/>
            </g>
            <defs>
              <clipPath id="clip0_23_1294">
                <rect width="110" height="110" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <!--        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--          <g clip-path="url(#clip0_23_1351)">-->
          <!--            <path d="M55 110C85.3757 110 110 85.3757 110 55C110 24.6243 85.3757 0 55 0C24.6243 0 0 24.6243 0 55C0 85.3757 24.6243 110 55 110Z" fill="#DA0505"/>-->
          <!--            <path d="M58.9063 54.5L72.3438 41.0937C72.9323 40.5053 73.2628 39.7072 73.2628 38.875C73.2628 38.0428 72.9323 37.2447 72.3438 36.6562C71.7554 36.0678 70.9572 35.7372 70.1251 35.7372C69.2929 35.7372 68.4948 36.0678 67.9063 36.6562L54.5001 50.0937L41.0938 36.6562C40.5054 36.0678 39.7072 35.7372 38.8751 35.7372C38.0429 35.7372 37.2448 36.0678 36.6563 36.6562C36.0679 37.2447 35.7373 38.0428 35.7373 38.875C35.7373 39.7072 36.0679 40.5053 36.6563 41.0937L50.0938 54.5L36.6563 67.9062C36.3634 68.1967 36.1309 68.5424 35.9723 68.9232C35.8136 69.304 35.7319 69.7124 35.7319 70.125C35.7319 70.5375 35.8136 70.946 35.9723 71.3268C36.1309 71.7076 36.3634 72.0532 36.6563 72.3437C36.9468 72.6366 37.2924 72.8691 37.6733 73.0278C38.0541 73.1864 38.4625 73.2681 38.8751 73.2681C39.2876 73.2681 39.696 73.1864 40.0769 73.0278C40.4577 72.8691 40.8033 72.6366 41.0938 72.3437L54.5001 58.9062L67.9063 72.3437C68.1968 72.6366 68.5424 72.8691 68.9233 73.0278C69.3041 73.1864 69.7125 73.2681 70.1251 73.2681C70.5376 73.2681 70.946 73.1864 71.3269 73.0278C71.7077 72.8691 72.0533 72.6366 72.3438 72.3437C72.6367 72.0532 72.8692 71.7076 73.0278 71.3268C73.1865 70.946 73.2682 70.5375 73.2682 70.125C73.2682 69.7124 73.1865 69.304 73.0278 68.9232C72.8692 68.5424 72.6367 68.1967 72.3438 67.9062L58.9063 54.5Z" fill="white"/>-->
          <!--          </g>-->
          <!--          <defs>-->
          <!--            <clipPath id="clip0_23_1351">-->
          <!--              <rect width="110" height="110" fill="white"/>-->
          <!--            </clipPath>-->
          <!--          </defs>-->
          <!--        </svg>-->
        </div>
        <div class="mt-10 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          Modelo:
        </span>
          <span class="font-montserrat-bold">
          {{ data.Modelo }}
        </span>
        </div>
        <v-divider class="my-2 mx-5"></v-divider>
        <div class="mt-3 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          Order Trabajo:
        </span>
          <span class="font-montserrat-bold">
          {{ data.work_order }}
        </span>
        </div>
        <v-divider class="my-2 mx-5"></v-divider>
        <div class="mt-3 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          Lat:
        </span>
          <span class="font-montserrat-bold">
          40.355
        </span>
          -
          <span class="font-montserrat-regular">
          Lon:
        </span>
          <span class="font-montserrat-bold">
           68.245
        </span>
        </div>
        <v-divider class="my-2 mx-5"></v-divider>
        <div class="mt-3 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          Perdidas:
        </span>
          <span class="font-montserrat-bold">
          {{ data.Perdidas }}
        </span>
          -
          <span class="font-montserrat-regular">
          Izq:
        </span>
          <span class="font-montserrat-bold">
           {{ data.Izq }}
        </span>
          -
          <span class="font-montserrat-regular">
          Der:
        </span>
          <span class="font-montserrat-bold">
           {{ data.Der }}
        </span>
        </div>
        <v-divider class="my-2 mx-5"></v-divider>
        <div class="mt-3 text-left mx-5 w-full">
        <span class="font-montserrat-regular">
          T. registro :
        </span>
          <span class="font-montserrat-bold">
          {{ data.t_registro }}
        </span>
        </div>
        <v-divider class="my-2 mx-5"></v-divider>
      </div>
      <div class="mt-2 px-5 pb-5 row-start-2 absolute bottom-5 w-full">
        <v-btn @click="goHome" block color="#4A4D4E" class="white--text font-montserrat-light capitalize justify-between" x-large>
          Pagina Principal
          <v-icon
              right
              dark
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>
        <v-btn @click="goToUpload" block color="#4A4D4E" outlined class="text-theme-3 font-montserrat-light capitalize justify-between mt-3" x-large>
          Nuevo Orden
          <v-icon
              right
              dark
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Verification",
  data() {
    return {
      data: null
    }
  },
  computed: {
    ...mapGetters({
      verification: "uploadImage/verificationGetter"
    })
  },
  mounted() {
    this.data = JSON.parse(this.verification)
    if (this.$session.get("username")) {
      return true
    } else {
      this.$router.push("/")
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    },
    goHome() {
      return this.$router.push("/home")
    },
    goToUpload() {
      return this.$router.push("/add-new")
    }
  }
}
</script>
<style lang="scss" scoped>
.top-bg {
  height: 90px;
  z-index: 2;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
.inner-bg {
  height: 95px;
  background: #F48B8B;
  z-index: 1;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
.v-btn {
  border-radius: 12px !important;
  ::v-deep &__content {
    justify-content: space-between;
  }
}
</style>

import Vue from 'vue'
import VueRouter from  'vue-router'
import Orders from "@/components/Orders";
import OrderDetails from "@/components/OrderDetails";
import UploadImage from "@/components/UploadImage";
import SignIn from "@/components/SignIn";
import Verification from "@/components/Verification";
import NotVerified from "@/components/NotVerified";
import NotRecognizingImg from "@/components/NotRecognizingImg";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "SingIn",
        component: SignIn
    },
    {
        path: "/home",
        name: "Home",
        component: Orders
    },
    {
        path: "/details/:id",
        name: "details",
        component: OrderDetails
    },
    {
        path: "/add-new",
        name: "cameraUpload",
        component: UploadImage
    },
    {
        path: "/complete",
        name: "complete",
        component: Verification
    },
    {
        path: "/not-complete",
        name: "not complete",
        component: NotVerified
    },
    {
        path: "/problem",
        name: "not recognized",
        component: NotRecognizingImg
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
